
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="activity.name" autofocus="autofocus" required="required">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Modalidade</label>
          <InputSelect collection="modalities" object="modality" fieldKey="id" fieldText="name" v-bind:current="activity.modality_id" @select="(sel) => activity.modality_id = sel" rows="200"/>
        </div>
        <div class="form-group col-md-4">
          <label>Local</label>
          <InputSelect collection="locations" object="location" fieldKey="id" fieldText="name" v-bind:current="activity.location_id" @select="(sel) => activity.location_id = sel" rows="200"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-1">
          <input type="checkbox" id="Dom" value="0" v-model="checkedDays">
          <label class="ml-1" for="Dom">Dom.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Seg" value="1" v-model="checkedDays">
          <label class="ml-1" for="Seg">Seg.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Ter" value="2" v-model="checkedDays">
          <label class="ml-1" for="Ter">Ter.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Qua" value="3" v-model="checkedDays">
          <label class="ml-1" for="Qua">Qua.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Qui" value="4" v-model="checkedDays">
          <label class="ml-1" for="Qui">Qui.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Sex" value="5" v-model="checkedDays">
          <label class="ml-1" for="Sex">Sex.</label>
        </div>
        <div class="form-group col-md-1">
          <input type="checkbox" id="Sab" value="6" v-model="checkedDays">
          <label class="ml-1" for="Sab">Sab.</label>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="name">Hora início</label>
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.starttime" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-2">
          <label for="name">Hora fim</label>
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.endtime" autofocus="autofocus" required="required">
        </div>
        <div class="form-group col-md-5">
					<label>Responsável</label>
					<InputSelect collection="users" object="user" fieldKey="id" fieldText="firstname" v-bind:current="activity.professional_user_id" @select="(sel) => activity.professional_user_id = sel" rows="200"/>
				</div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Observação</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="activity.observation" autofocus="autofocus" required="required">
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="inative">
          <label class="form-check-label" for="inative">
            Inativo
          </label>
        </div>
      </div>

      <FormAssociate v-bind:activity_id="this.$route.params.id" @saved="refetchAssociates" />
      
      <SimpleDataTable v-if="this.$route.params.id" ref="listAssociates" v-bind:specificEndpoint="tableOptions.endpoint" v-bind:object="tableOptions.object" v-bind:fields="tableOptions.fields" v-bind:filter="tableOptions.filter" />
      
      <div style="float: right;">
        <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
        <router-link class="btn btn-default" style="margin-left:10px;" to="/activities">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';
import InputSelect from '../commons/InputSelect.vue';
import SimpleDataTable from '../commons/SimpleDataTable.vue';
import FormAssociate from './FormAssociate.vue';

export default {
  props: {
    save: Function,
  },
  components: {
    InputSelect,
    SimpleDataTable,
    FormAssociate
  },
  data() {
    return {
      activity: {},
      validationErrors: [],
      checkedDays: [],
      tableOptions: {
        endpoint: `activities/${this.$route.params.id}/associates`,
        object: 'activityAssociates',
        fields: [ 
          { 'name': 'id', 'caption': '#' },
          { 'name': 'name', 'relation': 'associate', 'caption': 'Associado' },
          { 'name': 'titlenumber', 'relation': 'associate', 'caption': 'Título' },
          { 'name': 'associatenumber', 'relation': 'associate', 'caption': 'Cartão' },
        ],
        filter: 'name',
      }
    };
  },
  mounted() {
    this.fetchGetLocation();
  },
  methods: {
    refetchAssociates() {
      this.$refs.listAssociates.fetchGetData();
    },
    async fetchGetLocation() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`activities/${this.$route.params.id}`);
      this.activity = res.data.activity;
      if (this.activity.days) this.checkedDays = this.activity.days;
      this.$root.loadingVisible = false;
    },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }

      this.activity.days = this.checkedDays;
      
      this.save( this.activity );
    },
    validate() {
      const errors = [];
      if (!this.activity.name) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
