<template>
	<select :class="readonly ? 'form-control select-disabled' : 'form-control'" v-model="selected" :aria-disabled="this.readonly">
		<option 
			v-for="item in list" 
			:key="item[fieldKey]" 
			:value="item[fieldKey]">
			{{ item[fieldText] }}
		</option>
    </select>
</template>

<script>

import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
	props: {
		collection: String,
        object: String,
		fieldKey: String,
		fieldText: String,
        current: String,
        rows: String,
		markGroup: Boolean,
		readonly: Boolean,
	},
	emits: ["select"],
	data() {
		return {
            list: [],
			selected: '',
		}
	},
    created() {
		this.fetchList();
	},
	watch: {
		current(){
			this.selected = this.current;
        },
        selected(){
			this.$emit('select', this.selected);
        }
    },
	methods: {
		fetchList() {
			ApiAxios.get(`${this.collection}`, {
				params: {
					fields: `${this.fieldKey},${this.fieldText}`,
					page: 0,
					sort: this.fieldText,
                    size: this.rows || 10,
				}
			})
			.then((res) => {
                this.list = res.data[this.collection];
			})
			.catch((e) => {
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
	}
}
</script>

<style scoped>
	.select-disabled {
		background: #EEE; 
		pointer-events: none;
		touch-action: none;
	}
</style>